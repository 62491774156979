/***
 * @name Google Map
 *
 * @description
 *
 */

import React, { useEffect, useRef, useState } from 'react'

import ScriptLoader from '@src/utils/script-loader'

// == Local imports
import GoogleMapWrapper from '@src/apps/DealerMap/services/GoogleMapService'
import { IDealerInfo, IGoogleMap } from '@src/apps/DealerMap/types'

//
// == Global declaration for google API
declare global {
  interface Window {
    google: any
  }
}

//
const GoogleMap: React.FC<IGoogleMap> = ({
  activeDealer,
  addressKeyword,
  center,
  showInfo,
  enableSearch = false,
  geoCoords,
  googleApiKey,
  markers,
  onItemSelect,
  onViewChanged,
}) => {
  //
  // == State & references
  const mapRef = useRef()
  const [map, setMap] = useState(null)

  //
  // Method: Creates new instance of Google Maps
  const setupMap = () => {
    const instance = new GoogleMapWrapper({
      $ref: mapRef.current,
      center,
      zoom: 15,
    })

    instance.addMarkers(markers)

    instance.onViewChanged = (items: IDealerInfo[]) => {
      onViewChanged(items)
    }

    instance.onItemSelect = (item: IDealerInfo) => {
      onItemSelect(item)
    }

    instance.setFilterOnUpdate(enableSearch)

    setMap(instance)
  }

  //
  //
  // Life-cycle hook: When mounted -> load Google Maps API
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      return
    }

    ScriptLoader.loadScript(
      `https://maps.google.com/maps/api/js?key=${googleApiKey}&libraries=places,geometry&callback`
    ).then(() => {
      setupMap()
    })
  }, [])

  // Life-cycle hook: Initialization of state (map, markers and services are read)
  useEffect(() => {
    if (map) {
      if (activeDealer) {
        map.jumpTo(activeDealer.location_lat, activeDealer.location_long, 15)
      } else {
        setTimeout(() => map.showAllMarkers(), 100)
      }
    }
  }, [map])

  // Life-cycle hook: When external geolocation is used -> Zoom to position
  useEffect(() => {
    if (map) {
      if (geoCoords) {
        map.setOrigin(geoCoords.latitude, geoCoords.longitude)
      } else {
        map.showAllMarkers()
      }
    }
  }, [geoCoords])

  // Life-cycle hook: When address keyword is submitted -> Do lookup and zoom to position
  useEffect(() => {
    if (map) {
      if (addressKeyword === '') {
        map.showAllMarkers()
      } else {
        map.search(addressKeyword)
      }
    }
  }, [addressKeyword])

  // Life-cycle hook: When user clicks on an marker
  useEffect(() => {
    if (map) {
      if (activeDealer && showInfo) {
        map.jumpTo(activeDealer.location_lat, activeDealer.location_long, 15)
        map.setMarkerState(activeDealer, showInfo)
      }
    }
  }, [activeDealer])

  // Life-cycle hook: When the
  useEffect(() => {
    if (map) {
      if (markers.length > 1 && activeDealer) {
        if (showInfo) {
          map.jumpTo(activeDealer.location_lat, activeDealer.location_long, 15)
        } else {
          map.goBack()
        }

        map.setMarkerState(activeDealer, showInfo)
      }
    }
  }, [showInfo])

  //
  // Render
  return (
    <div className={enableSearch && !showInfo ? 'google-map is-minimized' : 'google-map'}>
      <div className="google-map__component" ref={mapRef} />
    </div>
  )
}

export default GoogleMap
