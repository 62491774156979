/***
 * @name Side panel
 *
 * @description
 */

import React, { useEffect, useRef, useState } from 'react'

// == Local imports
import DealerSearch from '@src/apps/DealerMap/components/DealerSearch'
import { INavigate, ISidePanel } from '@src/apps/DealerMap/types'
import { bodyScrollIntoView } from '@src/utils/scrollTo'
import DealerInfo from './DealerInfo'
import DealerList from './DealerList'
import SidePanelHeader from './SidePanelHeader'

const $DealerMap: HTMLElement = document.querySelector('.js-dealer-map')

const SidePanel: React.FC<ISidePanel & INavigate> = ({
  dealer = null,
  overviewUrl = null,
  enableSearch = false,
  locations,
  onDealerSelect,
  onDealerSearch,
  onGeolocationRequest,
  onGoHome,
  showDistances,
  showInfo,
  showBackButton,
  viewMode,
}) => {
  //
  // == State
  const [show, setShow] = useState(false)
  const dealerListRef = useRef()
  const dealerInfoRef = useRef()

  //
  // == Lifecycle hook: On mount -> show panel after a short period of time
  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 500)
  }, [])

  // == Life-cycle hook:
  useEffect(() => {
    if (locations.length > 1 && window.innerWidth < 768 && viewMode !== 'viewMode') {
      if (showInfo) {
        setTimeout(() => {
          bodyScrollIntoView($DealerMap).then(() => {
            if (dealerInfoRef.current) {
              const bounds: ClientRect = (
                dealerInfoRef.current as HTMLElement
              ).getBoundingClientRect()
              const list: HTMLElement = dealerListRef.current as HTMLElement
              list.style.height = `${bounds.height}px`
            }
          })
        }, 250)
      } else {
        const list: HTMLElement = dealerListRef.current as HTMLElement
        list.style.height = null
      }
    }
  }, [showInfo])

  //
  // Render
  return (
    <div className={show ? 'side-panel show-panel' : 'side-panel'}>
      <div className={showInfo ? 'side-panel__container show-page' : 'side-panel__container'}>
        <div className="dealer-list side-panel__page" ref={dealerListRef}>
          {enableSearch && (
            <DealerSearch
              dealersFound={locations.length}
              onSubmit={onDealerSearch}
              onGeolocationRequest={onGeolocationRequest}
            />
          )}
          <DealerList
            locations={locations}
            onDealerSelect={onDealerSelect}
            showDistances={showDistances}
            viewMode={viewMode}
          />
        </div>
        <div className="side-panel__page" ref={dealerInfoRef}>
          {showBackButton && (
            <SidePanelHeader
              overviewUrl={viewMode === 'contact_detail' && overviewUrl ? overviewUrl : null}
              onGoHome={onGoHome}
            />
          )}
          <DealerInfo
            overviewUrl={overviewUrl}
            isMultiple={locations.length > 1}
            viewMode={viewMode}
            {...dealer}
          />
        </div>
      </div>
    </div>
  )
}

export default SidePanel
