/***
 * @name Dealer Map bootstrapper
 *
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import App from '@src/apps/DealerMap/components/App'

const $container: HTMLElement = document.querySelector('.js-dealer-map')
const root = createRoot($container)

// Bump

if (
  $container &&
  $container.dataset.googleApiKey !== '' &&
  $container.dataset.dealerApiUrl !== ''
) {
  root.render(
    <App
      overviewUrl={$container.dataset.overviewUrl}
      dealerApiUrl={$container.dataset.dealerApiUrl}
      dealerLocationId={Number($container.dataset.dealerLocationId)}
      googleApiKey={$container.dataset.googleApiKey}
      viewMode={$container.dataset.viewMode}
      $root={$container}
    />
  )
}
