/***
 * @name Dealer list item
 *
 */

import React from 'react'

import { IDealerInfo, IDealerSelectable } from '@src/apps/DealerMap/types'

//
// == Global declaration for window location
declare global {
  interface Window {
    location: Location
  }
}

const linkClickHandler = (e: any) => {
  e.stopPropagation()
}

const DealerItem: React.FC<IDealerInfo & IDealerSelectable> = ({
  address_data,
  contact_url = null,
  dealerNumber,
  dealer_location_id,
  distance,
  onDealerSelect,
  photo,
  phone_number_formatted,
  phone_number_readable,
  short_name,
  showDistances,
  viewMode,
  whatsapp_url,
  whatsapp_readable,
}) => {
  const selectDealerHandler = () => {
    if (viewMode === 'contact_overview' && contact_url) {
      window.location.replace(contact_url)
    } else {
      onDealerSelect(dealer_location_id)
    }
  }

  const addressHTML: string = address_data.replace(/\n/g, '<br />')

  return (
    <div className="dealer-item" onClick={selectDealerHandler}>
      {photo && <img className="dealer-item__image" src={photo.url} alt={short_name} />}
      <div className="dealer-item__body" data-number={dealerNumber}>
        <h6>{short_name}</h6>
        <p dangerouslySetInnerHTML={{ __html: addressHTML }} />
        {phone_number_formatted && (
          <a
            href={`tel:${phone_number_formatted}`}
            className="dealer-info__btn is-big"
            onClick={linkClickHandler}
          >
            <span className="icon icon-phone" />
            <span>{phone_number_readable || phone_number_formatted}</span>
          </a>
        )}
        {whatsapp_url && (
          <a
            href={whatsapp_url}
            className="dealer-info__btn is-big"
            onClick={linkClickHandler}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="icon icon-whatsapp" />
            <span>{whatsapp_readable || whatsapp_url}</span>
          </a>
        )}
        <i className="icon icon-angle-right" />
        {showDistances && distance !== undefined && !isNaN(distance) && (
          <div className="dealer-item__distance">{distance} km</div>
        )}
      </div>
    </div>
  )
}

export default DealerItem
