/***
 * @name Dealer Map - App
 *
 * @description Main application that loads API data and determines functionality based
 * upon number of dealers
 *
 */

import React, { useEffect, useState } from 'react'

// == Local imports
import { apiGet } from '@src/api/client'
import { IApp, IDealerInfo } from '@src/apps/DealerMap/types'
import IGeolocation from '@src/apps/DealerMap/types/IGeolocation'
import GoogleMap from './GoogleMap'
import Loader from './Loader'
import SidePanel from './SidePanel'

// Used for debug and demonstration purposes
const MIN_DEALERS_FOR_SEARCH: number = window.location.hash === '#show-search' ? 0 : 8

//
const App: React.FC<IApp> = ({
  overviewUrl,
  dealerApiUrl,
  dealerLocationId,
  googleApiKey,
  viewMode,
  $root,
}) => {
  //
  // == State:
  const [dealer, setDealer] = useState(null)
  const [dealers, setDealers] = useState(null)
  const [locations, setFiltered] = useState(null)
  const [geoCoords, setGeoCoords] = useState(null)
  const [addressKeyword, setAddressKeyword] = useState('')
  const [showInfo, setShowInfo] = useState(false)

  //
  // == Method: Fetch dealers via API
  const fetchDealers = async () => {
    const response: any = await apiGet(dealerApiUrl)
    const data = response.data

    // Used for debugging and demonstration purposes - can pass in a number of max-items
    const hash: string = window.location.hash
    if (hash.indexOf('#max-dealers/') === 0) {
      const MAX_ITEMS: number = Number(hash.split('#max-dealers/')[1])

      if (MAX_ITEMS > 0) {
        while (data.locations.length > MAX_ITEMS) {
          data.locations.pop()
        }
      }
    }

    const NUM_ITEMS: number = data.locations.length

    if (NUM_ITEMS >= MIN_DEALERS_FOR_SEARCH) {
      $root.classList.add('has-search-enabled')
    }

    if (NUM_ITEMS < 3) {
      $root.classList.add('has-divider-on-last-item')
    }

    setDealers(data)
    setFiltered([...data.locations])

    // ...
    if (dealerLocationId) {
      // We have to add the default order of the dealer item on startup if we want to see ones
      const item: IDealerInfo = data.locations.find((el: IDealerInfo, index: number) => {
        el.order = index + 1
        return el.dealer_location_id === dealerLocationId
      })

      if (item) {
        setDealer(item)
        setShowInfo(true)
      }
    }

    // Trigger lazy loading
    if ('lazyLoadInstance' in window) {
      ;(window as any).lazyLoadInstance.update()
    }
  }

  //
  // == Method - event handler: When map changed (by user) -> updated filtered list
  const viewChangeHandler = (items: IDealerInfo[]): void => {
    setFiltered(items)
  }

  // == Method - event handler: When a marker is selected -> show dealer information
  const markerSelectHandler = (item: IDealerInfo): void => {
    setDealer(item)
    setShowInfo(true)
  }

  // == Method - event handler: When a dealer in the list is selected -> show dealer information
  const dealerSelectHandler = (id: number): void => {
    const item: IDealerInfo = dealers.locations.find(
      (el: IDealerInfo) => el.dealer_location_id === id
    )

    if (item) {
      setDealer(item)
      setShowInfo(true)
    }
  }

  // == Method - event handler:
  const dealerSearchHandler = (value?: string): void => {
    if (value) {
      setAddressKeyword(value)
    } else {
      setFiltered([...dealers.locations])
      setGeoCoords(null)
      setAddressKeyword('')
    }
  }

  // == Method - event handler: When go home is triggered -> Go back to list
  const goHomeHandler = () => {
    setShowInfo(false)
  }

  // Method - event handler:
  const geolocationRequestHandler = (coords: IGeolocation) => {
    setGeoCoords(coords)
  }

  // == Life-cycle hook: When mounted -> fetch dealers via API
  useEffect(() => {
    fetchDealers()
  }, [])

  // === Life-cycle hook: Jump dealer info if only 1 location
  useEffect(() => {
    if (dealers && dealers.locations.length === 1 && dealer === null) {
      setShowInfo(true)
      setDealer(dealers.locations[0])
    }
  }, [dealers])

  //
  // == Life-cycle hook: Render
  return (
    <>
      {locations ? (
        <>
          <GoogleMap
            activeDealer={dealer}
            addressKeyword={addressKeyword}
            enableSearch={dealers.locations.length >= MIN_DEALERS_FOR_SEARCH}
            googleApiKey={googleApiKey}
            geoCoords={geoCoords}
            markers={dealers.locations}
            showInfo={showInfo}
            center={{ lat: 52.1326, lng: 5.2913 }}
            //
            onItemSelect={markerSelectHandler}
            onViewChanged={locations.length >= MIN_DEALERS_FOR_SEARCH && viewChangeHandler}
          />
          <SidePanel
            overviewUrl={overviewUrl}
            dealer={dealer}
            enableSearch={dealers.locations.length >= MIN_DEALERS_FOR_SEARCH}
            showDistances={addressKeyword !== '' || geoCoords !== null}
            locations={locations}
            showBackButton={dealers.locations.length > 1}
            showInfo={showInfo}
            viewMode={viewMode}
            //
            onDealerSelect={dealerSelectHandler}
            onDealerSearch={dealerSearchHandler}
            onGeolocationRequest={geolocationRequestHandler}
            onGoHome={goHomeHandler}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default App
