/***
 * @name Google Map Helper
 *
 * @description Abstracts a lot of the functionality away from the React component
 */

// == Local imports
import { IDealerInfo } from '@src/apps/DealerMap/types'
import TOUCH_SUPPORTED from '@src/utils/touch'

//
// ==
const MARKER_SINGLE = '/static/toyota/images/svgs/icon-marker.svg'
const MARKER_MULTIPLE_SELECTED = '/static/toyota/images/svgs/icon-marker-selected.svg'
const MARKER_MULTIPLE_UNSELECTED = '/static/toyota/images/svgs/icon-marker-unselected.svg'

//
// == Interface
interface IGoogleMapServiceOptions {
  $ref: { current: Node }
  center: any
  zoom: number
}

//
//
export default class GoogleMapService {
  public onViewChanged: any
  public onItemSelect: any

  private map: any
  private service: any
  private markers: IDealerInfo[]
  private $markers: any
  private filterOnUpdate: boolean = false

  private prevLat: number
  private prevLng: number
  private prevZoom: number
  private origin: any
  private activeMarker: any

  // Constructor - initialize Google Map and Service for
  constructor(options: IGoogleMapServiceOptions) {
    Object.assign(this, options)

    const { $ref, zoom, center } = options
    const { maps } = window.google

    // Map creation instance
    this.map = new maps.Map($ref, {
      animatedZoom: false,
      center,
      fullscreenControl: false,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoom,
      zoomControl: !TOUCH_SUPPORTED,
    })

    // Event listeners
    this.map.addListener('dragend', () => {
      if (this.filterOnUpdate) {
        this.filterMarkers(this.markers, false, false)
      }
    })

    this.map.addListener('zoom_changed', () => {
      if (this.filterOnUpdate) {
        this.filterMarkers(this.markers, false, false)
      }
    })

    this.service = new maps.places.PlacesService(this.map)
  }

  // Method: Add add a list of markers to the map
  public addMarkers = (markers: IDealerInfo[]): void => {
    const { maps } = window.google

    // Custom marker data
    this.markers = markers

    // Google Maps marker data
    this.$markers = {}

    const HAS_MULTIPLE_MARKERS: boolean = markers.length > 1

    // Add markers to map and cache value by dealer location id for fast lookup
    markers.map((item: IDealerInfo, index: number) => {
      let marker

      if (HAS_MULTIPLE_MARKERS) {
        marker = new maps.Marker({
          draggable: false,
          icon: {
            anchor: new maps.Point(16, 0),
            labelOrigin: new maps.Point(16, 16),
            scaledSize: new maps.Size(32, 49),
            size: new maps.Size(32, 49),
            url: MARKER_MULTIPLE_UNSELECTED,
          },
          label: {
            color: '#ffffff',
            fontSize: '16px',
            fontWeight: 'bold',
            text: String(index + 1),
          },
          map: this.map,
          optimized: false,
          position: { lat: item.location_lat, lng: item.location_long },
        })

        marker.addListener('click', () => {
          this.onItemSelect(item)
        })
      } else {
        marker = new maps.Marker({
          draggable: false,
          icon: {
            anchor: new maps.Point(16, 0),
            labelOrigin: new maps.Point(16, 16),
            scaledSize: new maps.Size(32, 49),
            size: new maps.Size(32, 49),
            url: MARKER_SINGLE,
          },
          map: this.map,
          optimized: false,
          position: { lat: item.location_lat, lng: item.location_long },
        })
      }

      this.$markers[item.dealer_location_id] = marker
    })
  }

  // Method: Clears all markers from the map
  public clearMarkers = (): void => {
    // TODO
  }

  // Method: Get all visible markers
  public showAllMarkers = (reposition: boolean = true): IDealerInfo[] => {
    return this.filterMarkers(this.markers, true, reposition)
  }

  // Method: Jump to position and zoom level; store previous values so can go back
  // TODO: Future improvement is to keep a stack of positions
  public jumpTo = (lat: number, lng: number, zoom: number) => {
    if (this.map) {
      this.prevZoom = this.map.getZoom()
      this.prevLat = this.map.getCenter().lat()
      this.prevLng = this.map.getCenter().lng()

      // We have to wait to store the values before updating...
      requestAnimationFrame(() => {
        this.map.setCenter({ lat, lng })
        this.map.setZoom(zoom)
      })
    }
  }

  // Method: Go back to previous value
  public goBack = () => {
    if (this.map) {
      if (this.prevZoom) {
        this.map.setZoom(this.prevZoom)
        this.prevZoom = null
      }
      if (this.prevLat && this.prevLng) {
        this.map.setCenter({ lat: this.prevLat, lng: this.prevLng })
        this.prevLat = null
        this.prevLng = null
      }

      if (this.filterOnUpdate) {
        requestAnimationFrame(() => {
          this.filterMarkers(this.markers, false, false)
        })
      }
    }
  }

  // Method: Set input markers to be in view
  public setMarkersInView = (markers: IDealerInfo[], reposition: boolean = false) => {
    return this.filterMarkers(markers, false, reposition)
  }

  // Method: Set state for a single marker
  public setMarkerState = (item: IDealerInfo, selected: boolean) => {
    const { maps } = window.google

    if (maps) {
      if (this.activeMarker) {
        const { text } = this.activeMarker.label
        this.setMarkerOptions(this.activeMarker, text, false)
        this.activeMarker = null
      }

      const marker = this.$markers[item.dealer_location_id]

      if (marker) {
        const { text } = marker.label
        this.setMarkerOptions(marker, text, selected)

        if (selected) {
          this.activeMarker = marker
        }
      }
    }
  }

  // Method: Set filter on update value (to trigger updating list)
  public setFilterOnUpdate = (value: boolean): void => {
    this.filterOnUpdate = value
  }

  // Method: Search by location
  public search = (query: string): void => {
    const { maps } = window.google

    if (query) {
      const request = {
        fields: ['name', 'geometry'],
        query,
      }

      this.service.findPlaceFromQuery(request, (results: any, status: any) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          if (results && results.length > 0) {
            const { location } = results[0].geometry
            const lat = location.lat()
            const lng = location.lng()

            this.origin = new maps.LatLng({ lat, lng })

            this.jumpTo(lat, lng, window.innerWidth < 768 ? 9 : 10)

            setTimeout(() => this.filterMarkers(this.markers, false, false), 150)
          }
        }
      })
    }
  }

  // Method: Set origin and sort markers by distance
  public setOrigin = (lat: number, lng: number): void => {
    const { maps } = window.google

    this.origin = new maps.LatLng({ lat, lng })

    this.jumpTo(lat, lng, 10)

    setTimeout(() => {
      this.filterMarkers(this.markers, false, false)
    }, 20)
  }

  // Method: Clear origin
  public clearOrigin = () => {
    this.origin = null

    this.markers.map((item: IDealerInfo) => {
      item.distance = null
    })
  }

  // Filter markers by dataset
  private filterMarkers = (
    data: IDealerInfo[],
    forceShow: boolean = false,
    reposition: boolean
  ): IDealerInfo[] => {
    const { maps } = window.google
    const visibleMarkers: IDealerInfo[] = []

    // Don't filter if there's an active marker
    if (this.activeMarker) {
      return
    }

    if (data && maps && this.map) {
      const bounds = new maps.LatLngBounds()

      data.map((item: IDealerInfo, index: number) => {
        const marker: any = this.$markers[item.dealer_location_id] as any

        // Check marker exists
        if (marker) {
          item.order = index + 1
          // Check if visible
          const mapBounds: any = this.map.getBounds()
          if (mapBounds && mapBounds.contains) {
            const visible: boolean = mapBounds.contains(marker.getPosition())

            // Add visible dealer to list
            if (visible || forceShow) {
              if (this.origin) {
                const destination = new maps.LatLng({
                  lat: item.location_lat,
                  lng: item.location_long,
                })
                const distance = maps.geometry.spherical.computeDistanceBetween(
                  this.origin,
                  destination
                )

                item.distance = Math.round((distance / 1000) * 10) / 10
              }

              visibleMarkers.push(item)

              if (reposition) {
                bounds.extend(marker.getPosition())
              }
            }
          }
        }
      })

      // Update filtered list
      if (this.onViewChanged && this.filterOnUpdate) {
        // Sort by distance if an origin (address) is provided
        if (this.origin) {
          visibleMarkers.sort((a, b) => {
            return a.distance - b.distance
          })
        }

        // Update markers
        visibleMarkers.map((item: IDealerInfo, index: number) => {
          const marker = this.$markers[item.dealer_location_id]

          if (marker) {
            item.order = index + 1

            this.setMarkerOptions(
              marker,
              `${index + 1}`,
              this.activeMarker && this.activeMarker.dealer_location_id === item.dealer_location_id
            )
          }
        })

        // Update numbering...
        this.onViewChanged(visibleMarkers)
      }

      // If request to reposition on checking markers in view -> fit too bounds and clamp zoom level
      if (reposition) {
        this.map.fitBounds(bounds)

        if (this.map.getZoom() > 15) {
          this.map.setZoom(15)
        }
      }
    }

    return visibleMarkers
  }

  // Private method:
  private setMarkerOptions = (marker: any, text: string, selected: boolean = false) => {
    const { maps } = window.google

    marker.setOptions({
      icon: {
        anchor: new maps.Point(16, 32),
        labelOrigin: new maps.Point(16, 16),
        scaledSize: new maps.Size(32, 49),
        size: new maps.Size(32, 49),
        url: selected ? MARKER_MULTIPLE_SELECTED : MARKER_MULTIPLE_UNSELECTED,
      },
      label: {
        color: selected ? '#000' : '#ffffff',
        fontSize: '16px',
        fontWeight: 'bold',
        text,
      },
      optimized: false,
    })
  }
}
