/***
 * @name Scroll Container
 *
 */

// == Third-party libraries
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import TOUCH_SUPPORTED from '@src/utils/touch'

// == Methods: Render function
const renderTrackVertical = () => <div className="side-panel__scrollbar-track" />
const renderThumbVertical = () => <div className="side-panel__scrollbar-thumb" />

//
const ScrollContainer: React.FC<{ children?: React.ReactNode }> = ({ children }) =>
  TOUCH_SUPPORTED ? (
    <Scrollbars style={{ overflowX: 'hidden' }}>{children}</Scrollbars>
  ) : (
    <Scrollbars
      renderTrackVertical={renderTrackVertical}
      renderThumbVertical={renderThumbVertical}
      style={{ overflowX: 'hidden' }}
    >
      <>{children}</>
    </Scrollbars>
  )

export default ScrollContainer
