/**
 * @name API client
 *
 * @credit Taken from Agradi by François
 */

import axios, { AxiosPromise } from 'axios'

import { IDealerLocation, ISearchResult } from '@src/api/types'

const baseURL = `${window.location.protocol}//${window.location.host}`

const client = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const apiGet = (url: string): AxiosPromise<any[]> => client.get(url)

export const apiGetUsedCars = (
  clientId: string,
  limit: number,
  model: string = '',
  isPrivateLease: boolean
) => {
  const filter = [
    { name: 'Brand', values: ['toyota'] },
    { name: 'ClientId', values: clientId.split(',') },
  ]
  if (model !== '') {
    filter.push({ name: 'Model', values: [model] })
  }

  if (isPrivateLease) {
    filter.push({ name: 'PriceLeasePilot', values: ['Private lease'] })
  }

  return client.post<ISearchResult>(
    `https://occasions.toyota.nl/api/search?source=toyota`,
    {
      filter,
      limits: { start: 0, limit },
      sort: 'RecentDesc',
    },
    {
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
    }
  )
}

export const apiGetDealers = (url: string) =>
  client.get<{ locations: IDealerLocation[] }>(url).then((x) => x.data)
