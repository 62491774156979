/***
 * @name Side panel header
 *
 */

import React from 'react'

import { INavigate } from '@src/apps/DealerMap/types'

interface ISidePanelHeader {
  overviewUrl?: string
}

const SidePanelHeader: React.FC<ISidePanelHeader & INavigate> = ({ overviewUrl, onGoHome }) => {
  const onClick = () => {
    if (overviewUrl) {
      window.location.replace(overviewUrl)
    } else {
      onGoHome()
    }
  }

  return (
    <div className="dealer-info__back">
      <button className="dealer-info__back-btn" onClick={onClick}>
        <i className="icon icon-angle-left" />
        <span>{overviewUrl ? ' Vind uw dealer' : 'Terug naar overzicht'}</span>
      </button>
    </div>
  )
}

export default SidePanelHeader
