/***
 * @name Loader
 *
 */

import React from 'react'

type Declarations = any

const Loader: React.FC<Declarations> = () => (
  <div className="dealer-loader">
    <div className="loader is-big is-red">Laden...</div>
  </div>
)

export default Loader
