/***
 * @name Dealer search
 *
 */

import React, { FormEvent, MouseEvent, useState } from 'react'

import { IDealerSearch } from '@src/apps/DealerMap/types'

const LOCATION_INITIAL: number = 0
const LOCATION_REQUESTING: number = 1
const LOCATION_REQUEST_SUCCESS: number = 2
const LOCATION_REQUEST_FAIL: number = 3

const DealerSearch: React.FC<IDealerSearch> = ({
  dealersFound = 0,
  onGeolocationRequest,
  onSubmit,
}) => {
  //
  // == State:
  const [value, setValue] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [location, setLocation] = useState(LOCATION_INITIAL)

  //
  // Method - event handler:
  const changeHandler = (e: FormEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value)
  }

  // Method - event handler:
  const clearClickHandler = (e: FormEvent): void => {
    e.preventDefault()

    setSubmitted(false)
    setValue('')
    setLocation(LOCATION_INITIAL)

    if (onSubmit) {
      onSubmit(null)
    }
  }

  // Method - event handler:
  const submitHandler = (e: FormEvent) => {
    e.preventDefault()

    if (onSubmit) {
      setSubmitted(true)
      onSubmit(value)

      setLocation(LOCATION_INITIAL)
    }
  }

  // Method - event handler:
  const geolocationRequestHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if ('geolocation' in navigator) {
      setLocation(LOCATION_REQUESTING)

      window.navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position.coords) {
            setLocation(LOCATION_REQUEST_SUCCESS)
            onGeolocationRequest(position.coords)
          } else {
            setLocation(LOCATION_REQUEST_FAIL)
          }
        },
        () => {
          setLocation(LOCATION_REQUEST_FAIL)
        }
      )
    } else {
      setLocation(LOCATION_REQUEST_FAIL)
    }
  }

  //
  // == Life-cycle hook: Render
  return (
    <div className="dealer-list__search">
      <form className="dealer-search" onSubmit={submitHandler}>
        <div className="dealer-search__title">
          <h5>Vind uw dealer</h5>
          <span>
            {dealersFound === 0 && ''}
            {dealersFound > 0 && (
              <>
                {dealersFound} {dealersFound === 1 ? 'dealer' : 'dealers'} gevonden
              </>
            )}
          </span>
        </div>
        <div className="dealer-search__input">
          <input
            type="text"
            value={value}
            onChange={changeHandler}
            placeholder="Zoek op plaats of postcode"
          />
          <button className="dealer-search__btn-submit" type="submit">
            <i className="glyphicon glyphicon-search" />
          </button>
          <button
            className={
              (value.length > 0 && submitted) ||
              location === LOCATION_REQUEST_FAIL ||
              location === LOCATION_REQUEST_SUCCESS
                ? 'dealer-search__btn-clear show-btn'
                : 'dealer-search__btn-clear'
            }
            onClick={clearClickHandler}
          >
            <i className="icon icon-remove" />
          </button>
        </div>
        <div className="dealer-search__footer">
          {location === LOCATION_INITIAL && (
            <button className="dealer-search__footer-indicator" onClick={geolocationRequestHandler}>
              <div className="dealer-search__footer-indicator-icon">
                <i className="icon icon-locate is-red" />
              </div>
              <span>Gebruik mijn huidige locatie</span>
            </button>
          )}
          {location === LOCATION_REQUESTING && (
            <div className="dealer-search__footer-indicator">
              <div className="dealer-search__footer-indicator-icon">
                <i className="loader is-small is-red" />
              </div>
              <span>Verzoek om toegang tot uw locatie</span>
            </div>
          )}
          {location === LOCATION_REQUEST_SUCCESS && (
            <div className="dealer-search__footer-indicator">
              <div className="dealer-search__footer-indicator-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13">
                  <path
                    d="M9.46875,17.5 C9.29687414,17.5 9.14453191,17.4687503 9.01171875,17.40625 C8.87890559,17.3437497 8.75781305,17.257813 8.6484375,17.1484375 L3.9609375,12.4609375 C3.72656133,12.2265613 3.609375,11.9492204 3.609375,11.6289062 C3.609375,11.3085921 3.72656133,11.0234388 3.9609375,10.7734375 C4.19531367,10.5390613 4.47265465,10.421875 4.79296875,10.421875 C5.11328285,10.421875 5.39843625,10.5390613 5.6484375,10.7734375 L9.46875,14.6171875 L18.3515625,5.8515625 C18.6015638,5.61718633 18.8867171,5.5 19.2070312,5.5 C19.5273454,5.5 19.8046863,5.61718633 20.0390625,5.8515625 C20.2734387,6.10156375 20.390625,6.38671715 20.390625,6.70703125 C20.390625,7.02734535 20.2734387,7.30468633 20.0390625,7.5390625 L10.3125,17.1484375 C10.2031245,17.257813 10.0820319,17.3437497 9.94921875,17.40625 C9.81640559,17.4687503 9.65625094,17.5 9.46875,17.5 Z"
                    transform="translate(-3 -5)"
                  />
                </svg>
              </div>
              <span>Niet juist? Gebruik het zoekvak om uw locatie in te voeren.</span>
            </div>
          )}
          {location === LOCATION_REQUEST_FAIL && (
            <div className="dealer-search__footer-indicator">
              <div className="dealer-search__footer-indicator-icon">
                <i className="glyphicon glyphicon-ban-circle is-red" />
              </div>
              <span>
                Er is een fout opgetreden. Gebruik het zoekvak om uw locatie in te voeren.
              </span>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default DealerSearch
