// Taken from https://github.com/maaaaark/bcSwipe/issues/4

let supportsTouch = false

if ('ontouchstart' in window) {
  // iOS & android
  supportsTouch = true
  /*
} else if (window.navigator.msPointerEnabled) {
  // Win8
  supportsTouch = true
  */
} else if ('ontouchstart' in document.documentElement) {
  // Controversal way to check touch support
  supportsTouch = true
}

export default supportsTouch
