/***
 * @name Dealer list
 *
 */

// == Third-party libraries
import React, { useState } from 'react'

// == Local files
import { IDealerInfo, IDealerList } from '@src/apps/DealerMap/types'
import useWindowResize from '@src/hooks/useWindowResize'
import DealerItem from './DealerItem'
import ScrollContainer from './ScrollContainer'

//
const DealerList: React.FC<IDealerList> = ({
  locations,
  onDealerSelect,
  showDistances,
  viewMode,
}) => {
  const [showMore, setShowMore] = useState(false)
  const windowSize = useWindowResize()

  const renderItems = () => {
    if (locations.length === 0) {
      return <div className="no-results-found">Geen resultaten gevonden.</div>
    }

    return locations.map((item: IDealerInfo, index: number) => (
      <DealerItem
        key={`item-${item.dealer_location_id}`}
        dealerNumber={index + 1}
        onDealerSelect={onDealerSelect}
        showDistances={showDistances}
        viewMode={viewMode}
        {...item}
      />
    ))
  }

  const buttonClickHandler = () => {
    setShowMore(!showMore)
  }

  const APPLY_BORDER: boolean = windowSize.innerWidth > 767 && locations.length < 3

  return (
    <div
      className={
        APPLY_BORDER ? 'dealer-list__container has-last-item-border' : 'dealer-list__container'
      }
    >
      {windowSize.innerWidth > 767 ? (
        locations.length > 3 ? (
          <ScrollContainer>{renderItems()}</ScrollContainer>
        ) : (
          <>{renderItems()}</>
        )
      ) : (
        <>
          <div className={showMore ? 'dealer-list__body show-more' : 'dealer-list__body'}>
            {renderItems()}
          </div>
          {locations.length > 4 && (
            <div className="dealer-list__footer">
              <button className="btn btn-chevron" onClick={buttonClickHandler}>
                <i className={showMore ? 'icon icon-chevron-up' : 'icon icon-chevron-down'} />
                <span>{showMore ? 'Toon minder' : 'Toon meer'}</span>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default DealerList
