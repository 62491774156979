/***
 * @name Dealer Info
 *
 */

import React from 'react'

import { IDealerInfo } from '@src/apps/DealerMap/types'
import { bodyScrollIntoView } from '@src/utils/scrollTo'

const linkClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.stopPropagation()
}

const DealerInfo: React.FC<IDealerInfo> = ({
  contact_url,
  statutory_name = null,
  address_data,
  phone_number_formatted,
  phone_number_readable,
  email,
  is_authorized_repairer,
  is_toyota_dealer,
  location_lat,
  location_long,
  opening_hour_friday,
  opening_hour_monday,
  opening_hour_saturday,
  opening_hour_sunday,
  opening_hour_tuesday,
  opening_hour_thursday,
  opening_hour_wednesday,
  opening_hours_exception,
  photo,
  short_name,
  order,
  whatsapp_url,
  whatsapp_readable,
  opening_hours_label,
  isMultiple,
  overviewUrl,
  viewMode,
  manager_name,
  manager_email,
  vat_number,
  coc_number,
}) => {
  if (statutory_name === null) {
    return <></>
  }

  const addressHTML = address_data.length > 3 ? address_data.replace(/\n/g, '<br />') : null

  const openingTimesClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    bodyScrollIntoView(document.querySelector('#openingstijden'))
  }

  return (
    <div className={isMultiple ? 'dealer-info is-multiple' : 'dealer-info'}>
      <div className="dealer-info__title" data-number={order}>
        <div className="dealer-info__title-text">
          <meta itemProp="name" content={short_name} />
          {photo && <meta itemProp="image" content={photo.url} />}
          {phone_number_formatted && <meta itemProp="telephone" content={phone_number_formatted} />}
          {email && <meta itemProp="email" content={email} />}
          <meta itemProp="address" content={addressHTML} />

          <h6>{short_name}</h6>
          {addressHTML && (
            <p className="dealer-info__address" dangerouslySetInnerHTML={{ __html: addressHTML }} />
          )}
          {phone_number_formatted && (
            <a
              className="dealer-info__btn is-big"
              href={'tel:' + phone_number_formatted}
              onClick={linkClickHandler}
            >
              <i className="icon icon-phone" />
              <span>{phone_number_readable || phone_number_formatted}</span>
            </a>
          )}
          {whatsapp_url && (
            <a
              href={whatsapp_url}
              className="dealer-info__btn is-big"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                className="icon icon-whatsapp"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
              </svg>
              <span>{whatsapp_readable || whatsapp_url}</span>
            </a>
          )}
          {email && (
            <a
              href={'mailto:' + email}
              className="dealer-info__btn is-big"
              onClick={linkClickHandler}
            >
              <i className="icon icon-email" />
              <span>{email}</span>
            </a>
          )}
        </div>
        {photo && (
          <div className="dealer-info__title-image">
            <img src={photo.url} alt={short_name} />
          </div>
        )}

        {viewMode === 'contact_detail' && (
          <div className="dealer-info__additional">
            {manager_name && (
              <div>
                <strong>Manager klantrelaties:</strong>
                {` `}
                {manager_name}
              </div>
            )}

            {manager_email && (
              <div>
                <strong>E-mail klantrelaties:</strong>
                {` `}
                <a href={'mailto:' + manager_email} onClick={linkClickHandler}>
                  <span>{manager_email}</span>
                </a>
              </div>
            )}

            {coc_number && (
              <div>
                <strong>KvK-nummer:</strong>
                {` `}
                {coc_number}
              </div>
            )}

            {vat_number && (
              <div>
                <strong>Btw-nummer:</strong>
                {` `}
                {vat_number}
              </div>
            )}
          </div>
        )}
      </div>

      {viewMode !== 'contact_detail' && (
        <div className="dealer-info__opening-times">
          <i className="icon icon-time" />
          <ul>
            <li>
              <strong className="dealer-info__opening-times__label">{opening_hours_label}</strong>
            </li>
            {opening_hour_monday && (
              <li>
                <span>Maandag</span> {opening_hour_monday}
                <meta itemProp="openingHours" content={`Mo ${opening_hour_monday}`} />
              </li>
            )}
            {opening_hour_tuesday && (
              <li>
                <span>Dinsdag</span> {opening_hour_tuesday}
                <meta itemProp="openingHours" content={`Tu ${opening_hour_tuesday}`} />
              </li>
            )}
            {opening_hour_wednesday && (
              <li>
                <span>Woensdag</span> {opening_hour_wednesday}
                <meta itemProp="openingHours" content={`We ${opening_hour_wednesday}`} />
              </li>
            )}
            {opening_hour_thursday && (
              <li>
                <span>Donderdag</span> {opening_hour_thursday}
                <meta itemProp="openingHours" content={`Th ${opening_hour_thursday}`} />
              </li>
            )}
            {opening_hour_friday && (
              <li>
                <span>Vrijdag</span> {opening_hour_friday}
                <meta itemProp="openingHours" content={`Fr ${opening_hour_friday}`} />
              </li>
            )}
            {opening_hour_saturday && (
              <li>
                <span>Zaterdag</span> {opening_hour_saturday}
                <meta itemProp="openingHours" content={`Sa ${opening_hour_saturday}`} />
              </li>
            )}
            {opening_hour_sunday && (
              <li>
                <span>Zondag</span> {opening_hour_sunday}
                <meta itemProp="openingHours" content={`Su ${opening_hour_sunday}`} />
              </li>
            )}
            {opening_hours_exception && (
              <li
                className="extra"
                dangerouslySetInnerHTML={{
                  __html: opening_hours_exception.replace(/\n/g, '<br />'),
                }}
              />
            )}
          </ul>
        </div>
      )}
      {(is_toyota_dealer || is_authorized_repairer) && (
        <div className="dealer-info__btn-group is-secondary">
          {is_toyota_dealer && (
            <p className="dealer-info__btn is-small">
              <i className="icon icon-car" />
              <span>Toyota Dealer</span>
            </p>
          )}
          {is_authorized_repairer && (
            <p className="dealer-info__btn is-small">
              <i className="icon icon-service-and-maintenance" />
              <span>Toyota Erkend Reparateur</span>
            </p>
          )}
        </div>
      )}
      <div className="dealer-info__btn-group block block-buttons is-primary">
        <a
          className="btn"
          href={`https://www.google.com/maps/dir//${location_lat},${location_long}/@${location_lat},${location_long},17z`}
          target="_blank"
          rel="noopener"
        >
          <i className="icon icon-map-marker" />
          <span>Route</span>
        </a>
        {viewMode === 'contact_detail' && (
          <a className="btn" href="#" onClick={openingTimesClickHandler}>
            <span>Openingstijden</span>
          </a>
        )}

        {viewMode === 'homepage' && (
          <a className="btn" href={contact_url || overviewUrl}>
            <span>Alle openingstijden</span>
          </a>
        )}

        {viewMode === 'contact_overview' && isMultiple && (
          <a className="btn" href={contact_url}>
            <span>Alle openingstijden</span>
          </a>
        )}
      </div>
    </div>
  )
}

export default DealerInfo
